import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { currencies } from "&config/currencies";
import SelectComponent from "&styled/form/select";
import { SelectChangeEvent } from "@mui/material/Select";
import CustomDatePicker from "&styled/date-picker";
import CountriesWithFlags from "&styled/countries-with-flags";
import { SubmitButton } from "&styled/button/button.component";
import CreateIcon from "&assets/icons/create";

type PageProps = {
  title: string;
  onSearch?: Function;
 renderCta?: any;
};
export default function PageHeader({
  title,
  onSearch,
  renderCta
}: PageProps) {
 
 

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ color: "#3C3C3C", fontSize: "20px", fontWeight: "bold" }}>
        {title}
      </Box>
      
        {renderCta && renderCta() }
    
      
    </Box>
  );
}
