import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { merchantsActions } from "&features/merchants/merchants.slice";
import {productsActions} from '&features/products/products.slice';
import SelectComponent from "&styled/form/select";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";

type ReduxProps = ConnectedProps<typeof connector>;

const ProductFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const {  addProduct, productId , getMerchants} = props;
  const [merchants, setMerchants] = useState<any[]>([])
  const [data, setData] = useState({
    _id: "",
    name: "",
    sku: "",
    attributes: {
      size:[],
      color:[],
      weight:"",
      productPrice:"",
      inventory:"",
      lowStockThreshold:""
    },
    merchantId:""
    
  });

  const validation = Yup.object().shape({
    merchantId: Yup.string().required("Please select a merchant"),
    name: Yup.string()
      .min(2, "Too short product name.")
      .max(50, "Too long product name")
      .required("Please provide valid product name")
  });

  //const fetchProduct = useCallback(
  //  async (id) => {
  //    try {
  //      const { payload } = await getMerchant(id);
  //      const merchant = { ...payload };
  //      delete merchant.createdDate;
  //      delete merchant.status;
  //      delete merchant.history;
  //      delete merchant.sid;
  //      delete merchant.publicKey;
  //      delete merchant.privateKey;
  //      delete merchant.token;
  //      delete merchant.__v;
  //      setData(merchant);
  //    } catch (ex) {
  //      console.log(ex);
  //      alert("Something went wrong");
  //    }
  //  },
  //  [getMerchant]
  //);

  useEffect(() => {
    const id = productId;
    (async()=>{
      const {payload} = await getMerchants();
      const formatted: any[] = [];
      payload.forEach((mer)=>{
        formatted.push({label:mer.name, value:mer._id})
      })
      setMerchants(formatted)
    })()
    if (id === "new") return;
    //fetchMerchant(id);
  }, [productId, getMerchants]);

  // Fetch revenue info

  const handleSubmit = async (vals) => {
    //if (data._id) {
    //  const { payload } = await editMerchant(vals);

    //  history.push("/merchants");
    //} else {
      const { payload } = await addProduct(vals);
      if (payload ) {
        history.push("/products");
      }
    //}
  };

  return (
    <>
      <PageHeader title="Product Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                 
                  <Grid item lg={4} sm={12}>
                    <SelectComponent
                      value={formik.values.merchantId}
                      onSelect={formik.handleChange("merchantId")}
                      menuItems={merchants}
                      placeHolder="Select Merchant"
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                  <TextInput
                      placeHolder={"Name"}
                      value={formik.values.name}
                      handleTextChange={formik.handleChange("name")}
                      hasError={!!formik.errors.name}
                      errorMessage={formik.errors.name as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"SKU"}
                      value={formik.values.sku}
                      handleTextChange={formik.handleChange("sku")}
                      hasError={!!formik.errors.sku}
                      errorMessage={formik.errors.sku as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    {/*<TextInput
                      placeHolder={"Currency"}
                      value={formik.values.currency}
                      handleTextChange={formik.handleChange("currency")}
                      hasError={!!formik.errors.currency}
                      errorMessage={formik.errors.currency as string}
                    />*/}
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    {/*<TextInput
                      placeHolder={"Notification Email"}
                      required={false}
                      value={formik.values.notificationEmail}
                      handleTextChange={formik.handleChange(
                        "notificationEmail"
                      )}
                      hasError={!!formik.errors.notificationEmail}
                      errorMessage={formik.errors.notificationEmail as string}
                    />*/}
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    {/*<TextInput
                      placeHolder={"Support Email"}
                      required={false}
                      value={formik.values.supportEmail}
                      handleTextChange={formik.handleChange("supportEmail")}
                      hasError={!!formik.errors.supportEmail}
                      errorMessage={formik.errors.supportEmail as string}
                    />*/}
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    {/*<TextInput
                      placeHolder={"Billing Email"}
                      required={false}
                      value={formik.values.billingEmail}
                      handleTextChange={formik.handleChange("billingEmail")}
                      hasError={!!formik.errors.billingEmail}
                      errorMessage={formik.errors.billingEmail as string}
                    />*/}
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    {/*<TextInput
                      placeHolder={"Commercial Email"}
                      required={false}
                      value={formik.values.commercialEmail}
                      handleTextChange={formik.handleChange("commercialEmail")}
                      hasError={!!formik.errors.commercialEmail}
                      errorMessage={formik.errors.commercialEmail as string}
                    />*/}
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  productId: ownProps.match.params.productId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getMerchants: merchantsActions.getMerchants,
  
  addProduct: productsActions.addProduct,
  editMerchant: merchantsActions.editMerchant,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const ProductFormComponentRedux = connector(ProductFormComponent);

export { ProductFormComponentRedux as ProductFormComponent };
