import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { carriersActions } from "&features/carriers/carriers.slice";
import SelectComponent from "&styled/form/select";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";
import {currencies} from '&config/currencies';

type ReduxProps = ConnectedProps<typeof connector>;

const CarrierFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { getCarrier, addCarrier, editCarrier, carrierId } = props;

  const [data, setData] = useState({
    _id: "",
    country: "",
    type: "",
    name: "",
    currency: "",
    notificationEmail: "",
    supportEmail: "",
    billingEmail: "",
    commercialEmail: "",
  });

  const validation = Yup.object().shape({
    country: Yup.string().required("Please select a department"),
    type: Yup.string().required("Please select type"),
    name: Yup.string()
      .min(2, "Too short account name.")
      .max(50, "Too long account name")
      .required("Please provide valid account name"),
    currency: Yup.string()
      .min(2, "Provided currency is invalid")
      .max(4, "Provided currency is invalid")
      .required("Please provide currency"),
    notificationEmail: Yup.string().optional(),
    supportEmail: Yup.string().optional(),
    billingEmail: Yup.string().optional(),
    commercialEmail: Yup.string().optional(),
  });

  const fetchCarrier = useCallback(
    async (id) => {
      try {
        const { payload } = await getCarrier(id);
        const carrier = { ...payload };
        delete carrier.createdDate;
        delete carrier.status;
        delete carrier.history;
        delete carrier.__v;
        setData(carrier);
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getCarrier]
  );

  useEffect(() => {
    const id = carrierId;
    if (id === "new") return;
    fetchCarrier(id);
  }, [carrierId, fetchCarrier]);

  // Fetch revenue info

  const handleSubmit = async (vals) => {
    if (data._id) {
      const { payload } = await editCarrier(vals);

      history.push("/carriers");
    } else {
      const { payload } = await addCarrier(vals);
      if (payload && payload.status === "Active") {
        history.push("/carriers");
      }
    }
  };
  const countries = [
    { label: "Pakistan", value: "Pakistan" },
    { label: "China", value: "China" },
    { label: "Singapore", value: "Singapore" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "United States", value: "United States" },
  ];
  const types = [
    { label: "Carrier", value: "Carrier" },
    { label: "Operator", value: "Operator" },
  ];
  return (
    <>
      <PageHeader title="Carrier Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={12}>
                    <SelectComponent
                      value={formik.values.country}
                      onSelect={formik.handleChange("country")}
                      menuItems={countries}
                      placeHolder="Select Country"
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <SelectComponent
                      value={formik.values.type}
                      onSelect={formik.handleChange("type")}
                      menuItems={types}
                      placeHolder="Select type"
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Account Name"}
                      value={formik.values.name}
                      handleTextChange={formik.handleChange("name")}
                      hasError={!!formik.errors.name}
                      errorMessage={formik.errors.name as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                  <SelectComponent
                      value={formik.values.currency}
                      onSelect={formik.handleChange("currency")}
                      menuItems={currencies}
                      placeHolder="Select Currency"
                    />
                   
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Notification Email"}
                      required={false}
                      value={formik.values.notificationEmail}
                      handleTextChange={formik.handleChange(
                        "notificationEmail"
                      )}
                      hasError={!!formik.errors.notificationEmail}
                      errorMessage={formik.errors.notificationEmail as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Support Email"}
                      required={false}
                      value={formik.values.supportEmail}
                      handleTextChange={formik.handleChange("supportEmail")}
                      hasError={!!formik.errors.supportEmail}
                      errorMessage={formik.errors.supportEmail as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Billing Email"}
                      required={false}
                      value={formik.values.billingEmail}
                      handleTextChange={formik.handleChange("billingEmail")}
                      hasError={!!formik.errors.billingEmail}
                      errorMessage={formik.errors.billingEmail as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Commercial Email"}
                      required={false}
                      value={formik.values.commercialEmail}
                      handleTextChange={formik.handleChange("commercialEmail")}
                      hasError={!!formik.errors.commercialEmail}
                      errorMessage={formik.errors.commercialEmail as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  carrierId: ownProps.match.params.carrierId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getCarrier: carriersActions.getCarrier,
  addCarrier: carriersActions.addCarrier,
  editCarrier: carriersActions.editCarrier,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const CarrierFormComponentRedux = connector(CarrierFormComponent);

export { CarrierFormComponentRedux as CarrierFormComponent };
